<template>
    <div class="order">
        <div class="order_Title">
            <div class="bookingSpace_Title"><span>{{ detailList.cate }}</span></div>
            <!-- 订单号 oceanShipping -->
            <div class="orderNumber">
                <div class="orderNumberBox">
                    <div class="orderNumber_Left">
                        <div class="img"><img src="../../assets/BookingSpace/Order.png" alt="" /></div>
                        <p>
                            <span>订单号：</span>
                            <span>{{ detailList.id }}</span>
                        </p>
                    </div>
                    <!--  -->
                    <div class="orderNumber_Right">
                        <div class="address">
                            <p>{{ detailList.departure }}</p>
                            <p><img src="../../assets/homePage/departureAndArrival.png" alt=""></p>
                            <p>{{ detailList.destination }}</p>
                        </div>
                        <div class="dateS"><span>{{ dataTime }}</span></div>
                    </div>
                </div>


            </div>
        </div>
        <!-- 收发人信息  -->
        <Information_Component></Information_Component>
        <!-- PersonalCenter -->
        <Footer_Compontent></Footer_Compontent>
    </div>
</template> 
<script>
import axios from 'axios'
import Information_Component from '../../components/bookingSpace/Information_Component.vue'
import Footer_Compontent from '../../components/Footer_Compontent.vue'
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "BookingSpace",
    components: { Information_Component, Footer_Compontent },
    data() {
        return {
            token: sessionStorage.getItem("token"),  //Token
            detailList: '', //详情
            dataTime: ""
        }
    },
    created() {
        axios.get(`${baseUrl}/inquiry/detail`, {
            //参数列表
            params: { id: this.$route.query.id },
            //请求头配置  
            headers: { 'authorization': this.token }
        })
            .then(res => {
                // console.log(res.data.result.updateTime)
                let times = res.data.result.updateTime.split(" ");
                this.dataTime = times[0]
                this.detailList = res.data.result
            })
            .catch(err => {
                console.error(err);
            })
    }
}
</script>

<style lang="scss" scoped>
.order {
    // position: relative;
    width: 100%;

    .order_Title {
        width: 1200px;
        margin: 58px auto 0;

        .bookingSpace_Title {
            font-size: 16px;
            font-weight: 800;
            color: #333333;
            line-height: 24px;
        }

        // 订单号
        .orderNumber {
            width: 1200px;
            height: 72px;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            margin-top: 16px;

            .orderNumberBox {
                display: flex;
                justify-content: space-between;
                width: 735px;

                .orderNumber_Left {
                    display: flex;
                    align-items: center;
                    height: 72px;
                    padding-left: 24px;

                    .img {
                        width: 22px;
                        height: 22px;
                        background: #292C35;

                        img {
                            display: block;
                            width: 14px;
                            height: 17px;
                            margin: auto;
                        }
                    }

                    p {
                        height: 22px;
                        background: #F6F6F6;
                        font-size: 14px;
                        padding: 4px 8px;

                        span:nth-child(1) {
                            font-weight: 400;
                            color: #333333;
                            font-family: '黑体';
                        }

                        span:nth-child(2) {
                            font-weight: 400;
                            color: #FFC13B;
                            font-family: '黑体';
                        }
                    }
                }

                .orderNumber_Right {
                    margin-top: 16px;

                    .address {
                        display: flex;
                        height: 18px;

                        p:nth-child(1) {
                            margin-right: 54px;
                            font-weight: bold;
                            color: #1890FF;

                            font-size: 18px;
                        }

                        p:nth-child(2) {
                            width: 72px;
                            height: 9px;
                            // background: #FFC13B;
                        }

                        p:nth-child(3) {
                            margin-left: 54px;
                            font-size: 18px;
                            font-weight: bold;
                            color: #1890FF
                        }
                    }

                    .dateS {
                        width: 80px;
                        height: 12px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #333333;
                        margin: 10px auto 0;
                    }
                }
            }


        }
    }
}
</style>