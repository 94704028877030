<template>
    <!-- 收发人信息 ItemInfo -->
    <div class="information">
        <!-- s发货 -->
        <div class="addressXin" v-show="addressClose">
            <div class="addressBOX">
                <div class="addressBOXTitle">
                    <span>地址簿</span>
                    <span @click="handleClose">X</span>
                </div>

                <div class="serverAdd">
                    <div class="serverInput">
                        <i class="el-icon-search"></i>
                        <input icon="el-icon-search" v-model="inputServe" @change="changeServe($event)"
                            placeholder="请输入关键词搜索" type="text">
                    </div>
                    <div class="serverAddBtn" @click="addAdd">新增地址</div>
                </div>

                <div class="tabs">
                    <el-table :data="addressList" style="width: 100%" :show-header="status">
                        <el-table-column fixed prop="company" label="公司名称" width="200" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div class="scopes">
                                    <div>
                                        <el-radio v-model="radio" :label="scope.$index + 1"
                                            @change.native="radioData(scope.row)"></el-radio>
                                        <span>{{ scope.row.company }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed prop="contact" label="姓名" width="100" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column fixed prop="tel" label="联系电话" width="150" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column fixed prop="addr" label="详细地址" width="250" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column fixed prop="email" label="邮箱" width="100" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="200" class-name="nana" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div class="bianji">
                                    <!-- 修改 -->
                                    <div class="img1" @click="handleXiuGai(scope.row)">
                                        <img src="../../assets/BookingSpace/xiugai.png" alt="" />
                                    </div>
                                    <!-- 删除 -->
                                    <div class="img2" @click="handleShanChu(scope.row)">
                                        <img src="../../assets/BookingSpace/shanchu.png" alt="" />
                                    </div>
                                </div>
                                <!-- <el-button @click.native.prevent="modifyRow(scope.$index, scope.row)" type="text"
                                    size="small">
                                    
                                    修改
                                </el-button> -->
                                <!-- <el-button @click.native.prevent="deleteRow(scope.$index, scope.row)" type="text"
                                    size="small">
                                    移除
                                </el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="paging">
                    <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                        :total="paging.total" :page-size="paging.size">
                    </el-pagination>
                </div>
                <div @click="handleConfir" v-show="quereny1" class="confirmBtn">确认</div>
                <div @click="handleConfir2" v-show="quereny2" class="confirmBtn">确认</div>
                <div @click="handleConfir3" v-show="quereny3" class="confirmBtn">确认</div>
                <div @click="handleConfir4" v-show="quereny4" class="confirmBtn">确认</div>

            </div>
        </div>
        <!-- 新增 -->
        <div class="addressPu" v-show="addAddressS1">

            <div class="addressBox">
                <div class="close" @click="handleCloseAdd"><span>X</span></div>
                <div class="addressTitle">
                    <span>地址簿</span>
                </div>
                <!-- 输入框 -->
                <div class="addressInput">
                    <div class="addressInputLeft">
                        <div class="inputbox">
                            <p>公司名称</p>
                            <div class="nameInput inputs"><input v-model="corporateName" type="text"
                                    placeholder="请输入公司名称" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>手机/固话</p>
                            <div class="nameInput inputs"><input v-model="telPhone" type="text" placeholder="请填写联系方式" />
                            </div>
                        </div>


                        <div class="inputbox">
                            <p>邮箱</p>
                            <div class="nameInput inputs"><input v-model="mailboxs" type="text" placeholder="请输入邮箱" />
                            </div>
                        </div>
                    </div>
                    <div class="addressInputRight">
                        <!-- 姓名 -->
                        <div class="inputbox">
                            <p>姓名</p>
                            <div class="nameInput inputs"><input v-model="uName" type="text" placeholder="请输入姓名" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>详细地址</p>
                            <div class="nameInput inputs"><input v-model="address1" type="text"
                                    placeholder="请输入国家/州省/城市" /></div>
                        </div>
                        <!-- <div class="inputbox">
                            <p> &emsp;</p>
                            <div class="nameInput inputs"><input type="text" v-model="detailedAddress"
                                    placeholder="请填写详细地址（精确到门牌号）" /></div>
                        </div> -->
                        <div class="inputbox">
                            <p>邮政编码</p>
                            <div class="nameInput inputs"><input v-model="postalCode" type="text"
                                    placeholder="请填写邮政编码（选填）" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="addressPuBtn" @click="confirms">确认</div>
            </div>
        </div>

        <!-- 修改 -->
        <div class="addressPu xiugaiMask" v-show="modifyAddressS">
            <div class="addressBox">
                <div class="close" @click="modifyAddressS = false"><span>X</span></div>
                <div class="addressTitle">
                    <span>地址簿</span>
                </div>
                <!-- 输入框 -->
                <div class="addressInput">
                    <div class="addressInputLeft">
                        <div class="inputbox">
                            <p>公司名称</p>
                            <div class="nameInput inputs"><input v-model="modify.corporateName" type="text"
                                    placeholder="请输入公司名称" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>手机/固话</p>
                            <div class="nameInput inputs"><input v-model="modify.telPhone" type="text"
                                    placeholder="请填写联系方式" />
                            </div>
                        </div>


                        <div class="inputbox">
                            <p>邮箱</p>
                            <div class="nameInput inputs"><input v-model="modify.mailboxs" type="text"
                                    placeholder="请输入邮箱" /></div>
                        </div>
                    </div>
                    <div class="addressInputRight">
                        <!-- 姓名 -->
                        <div class="inputbox">
                            <p>姓名</p>
                            <div class="nameInput inputs"><input v-model="modify.uName" type="text"
                                    placeholder="请输入姓名" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>详细地址</p>
                            <div class="nameInput inputs"><input v-model="modify.address1" type="text"
                                    placeholder="请选择国家/州省/城市" /></div>
                        </div>
                        <!-- <div class="inputbox">
                            <p> &emsp;</p>
                            <div class="nameInput inputs"><input type="text" v-model="modify.detailedAddress"
                                    placeholder="请填写详细地址（精确到门牌号）" /></div>
                        </div> -->
                        <div class="inputbox">
                            <p>邮政编码</p>
                            <div class="nameInput inputs"><input v-model="modify.postalCode" type="text"
                                    placeholder="请填写邮政编码（选填）" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 智能地址填写 -->
                <!-- <div class="intelligence">
                    <p class="intelligenceTitle">智能地址填写</p>
                    <div class="intelligenceInput">
                        <div class="textarea">
                            <textarea name="" v-model="intelligenceS" id="textarea" cols="30" rows="10"
                                placeholder="例如:张思, 13800138000,广东省广州市天河区某某大1001号"></textarea>

                        </div>

                        <div class="intelligenceBtn">
                            <div class="btn1">清除</div>
                            <div class="btn1">识别</div>
                        </div>
                    </div>
                </div> -->

                <div class="addressPuBtn" @click="modifyConfirms">确认</div>
            </div>
        </div>


        <!-- s收货货 -->
        <div class="addressXin" v-show="addressClose1">
            <div class="addressBOX">
                <div class="addressBOXTitle">
                    <span>地址簿</span>
                    <span @click="handleClose">X</span>
                </div>

                <div class="serverAdd">
                    <div class="serverInput">
                        <i class="el-icon-search"></i>
                        <input icon="el-icon-search" v-model="inputServe" @change="changeServe($event)"
                            placeholder="请输入关键词搜索" type="text">
                    </div>
                    <div class="serverAddBtn" @click="addAdd1">新增地址</div>
                </div>

                <div class="tabs">
                    <el-table :data="addressList" style="width: 100%" :show-header="status">
                        <el-table-column fixed prop="company" label="公司名称" width="200" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div class="scopes">
                                    <div>
                                        <el-radio v-model="radio1" :label="scope.$index + 1"
                                            @change.native="radioData1(scope.row)"></el-radio>
                                        <span>{{ scope.row.company }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed prop="contact" label="姓名" width="100" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column fixed prop="tel" label="联系电话" width="150" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column fixed prop="addr" label="详细地址" width="250" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column fixed prop="email" label="邮箱" width="100" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="200" class-name="nana" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <!-- <el-button @click.native.prevent="modifyRow(scope.$index, scope.row)" type="text"
                                    size="small">
                                    修改
                                </el-button>
                                <el-button @click.native.prevent="deleteRow(scope.$index, scope.row)" type="text"
                                    size="small">
                                    移除
                                </el-button> -->
                                <div class="bianji">
                                    <!-- 修改 -->
                                    <div class="img1" @click="handleXiuGai1(scope.row)">
                                        <img src="../../assets/BookingSpace/xiugai.png" alt="" />
                                    </div>
                                    <!-- 删除 -->
                                    <div class="img2" @click="handleShanChu(scope.row)">
                                        <img src="../../assets/BookingSpace/shanchu.png" alt="" />
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="paging">
                    <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                        :total="paging.total" :page-size="paging.size">
                    </el-pagination>
                </div>
                <div @click="handleConfir1" class="confirmBtn">确认</div>
            </div>
        </div>
        <!-- 收货新增 -->
        <div class="addressPu" v-show="addAddressS11">

            <div class="addressBox">
                <div class="close" @click="handleCloseAdd1"><span>X</span></div>
                <div class="addressTitle">
                    <span>地址簿</span>
                </div>
                <!-- 输入框 -->
                <div class="addressInput">
                    <div class="addressInputLeft">
                        <div class="inputbox">
                            <p>公司</p>
                            <div class="nameInput inputs"><input v-model="corporateName" type="text"
                                    placeholder="请输入公司名称" />
                            </div>
                        </div>

                        <div class="inputbox">
                            <p>详细地址</p>
                            <div class="nameInput inputs"><input v-model="address1" type="text"
                                    placeholder="请选择国家/州省/城市" /></div>
                        </div>
                        <div class="inputbox">
                            <p>邮箱</p>
                            <div class="nameInput inputs"><input v-model="mailboxs" type="text" placeholder="请输入邮箱" />
                            </div>
                        </div>
                    </div>
                    <div class="addressInputRight">
                        <!-- 姓名 -->
                        <div class="inputbox">
                            <p>姓名</p>
                            <div class="nameInput inputs"><input v-model="uName" type="text" placeholder="请输入姓名" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>手机/固话</p>
                            <div class="nameInput inputs"><input v-model="telPhone" type="text" placeholder="请填写联系方式" />
                            </div>
                        </div>
                        <!-- <div class="inputbox">
                            <p> &emsp;</p>
                            <div class="nameInput inputs"><input type="text" v-model="detailedAddress"
                                    placeholder="请填写详细地址（精确到门牌号）" /></div>
                        </div> -->
                        <div class="inputbox">
                            <p>邮政编码</p>
                            <div class="nameInput inputs"><input v-model="postalCode" type="text"
                                    placeholder="请填写邮政编码（选填）" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="addressPuBtn" @click="confirms">确认</div>
            </div>
        </div>

        <div class="information_Box">
            <div class="information_Title">
                <span>收发人信息</span>
            </div>
            <!-- 详细信息 -->
            <div class="detailedInformation">
                <!-- 发货方 -->
                <div class="shipper goods">
                    <!-- 姓名 -->
                    <div class="deliverGoods">
                        <p>发货方<span>*</span></p>
                        <div class="deliverGoods_Input">
                            <input type="text" v-model="shipperList.corporateName" required placeholder="请输入发货人公司">
                            <div class="img" @click="handelXinXi">
                                <img src="../../assets/BookingSpace/Location.png" alt="">
                            </div>
                        </div>
                    </div>
                    <!-- 电话 -->
                    <div class="telephoneNum">
                        <p>联系电话/固话<span>*</span></p>
                        <div class="telephoneNum_Input">
                            <input type="text" placeholder="请输入姓名" v-model="shipperList.shipperName">
                            <span>--</span>
                            <input type="text" v-model="shipperList.contactNumber" placeholder="请输入手机号/固话">
                        </div>
                    </div>
                    <!-- 详细地址 detailedAddress -->
                    <div class="detailedAddress">
                        <p>详细地址<span>*</span></p>
                        <div class="detailedAddress_Input">
                            <input type="text" v-model="shipperList.detailedAddress" placeholder="请输入地址">
                        </div>
                    </div>
                    <!-- 邮箱 mailbox -->
                    <div class="mailbox">
                        <p>邮箱</p>
                        <div class="mailbox_Input">
                            <input type="text" v-model="shipperList.mailbox" placeholder="请输入邮箱">
                        </div>
                    </div>
                </div>
                <!-- 收货方 -->
                <div class="shipTo goods">
                    <!-- 姓名 -->
                    <div class="deliverGoods">
                        <p>收货方<span>*</span></p>
                        <div class="deliverGoods_Input">
                            <input type="text" v-model="shipToList.corporateName" placeholder="请输入收货人公司">
                            <div class="img" @click="handelXinXi1">
                                <img src="../../assets/BookingSpace/Location.png" alt="">
                            </div>
                        </div>
                    </div>
                    <!-- 电话 -->
                    <div class="telephoneNum">
                        <p>联系电话/固话<span>*</span></p>
                        <div class="telephoneNum_Input">
                            <input type="text" placeholder="请输入姓名" v-model="shipToList.shipperName">
                            <span>--</span>
                            <input type="text" v-model="shipToList.contactNumber" placeholder="请输入手机号/固话">
                        </div>
                    </div>
                    <!-- 详细地址 detailedAddress -->
                    <div class="detailedAddress">
                        <p>详细地址<span>*</span></p>
                        <div class="detailedAddress_Input">
                            <input type="text" v-model="shipToList.detailedAddress" placeholder="请输入地址">
                        </div>
                    </div>
                    <!-- 邮箱 mailbox -->
                    <div class="mailbox">
                        <p>邮箱</p>
                        <div class="mailbox_Input">
                            <input type="text" v-model="shipToList.mailbox" placeholder="请输入邮箱">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 其他联系人信息 -->
        <div class="otherContacts">
            <div class="otherContacts_Title"><span>其他联系人信息</span></div>
            <div class="contactInformation">
                <!-- 通知人信息 -->
                <div class="notice">
                    <p>通知人信息</p>
                    <div class="notice_Input noticeS">
                        <input type="text" v-model="otherContacts.notifierInformation" placeholder="请选择通知人信息">
                        <div class="img" @click="handlenotice">
                            <img src="../../assets/BookingSpace/Location.png" alt="">
                        </div>
                    </div>
                </div>
                <!-- 装箱联系人 -->
                <div class="notice">
                    <p>装箱联系人</p>
                    <div class="notice_Input">
                        <input type="text" v-model="otherContacts.packingContact" placeholder="请选择装箱联系人信息">
                        <div class="img" @click="handlePacking">
                            <img src="../../assets/BookingSpace/Location.png" alt="">
                        </div>
                    </div>
                </div>
                <!-- 到门联系人 -->
                <div class="notice">
                    <p>到门联系人</p>
                    <div class="notice_Input">
                        <input type="text" v-model="otherContacts.doorToDoorContact" placeholder="请选择道门联系人信息">
                        <div class="img" @click="handleDoor">
                            <img src="../../assets/BookingSpace/Location.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 物品信息  -->
        <div class="itemInformation">
            <div class="itemInformation_Title"><span>物品信息</span></div>
            <div class="positionS">
                <!-- 仓位类型 -->
                <div class="positionType">
                    <p>装箱类型</p>
                    <div class="positionType_Input">
                        <el-select v-model="packingtypeValue" placeholder="请选择">
                            <el-option :value="packingtypeValue">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 箱型 -->
                <div class="positionType" v-show="packingtypeValue == 'FCL'">
                    <div class="positionType">
                        <p>箱型</p>
                        <div class="positionType_Input">
                            <el-select v-model="boxTypeValue" placeholder="请选择">
                                <el-option :value="boxTypeValue">
                                </el-option>
                            </el-select>
                            <!-- <el-select v-model="value" placeholder="请选择">
                            <option value=""></option>
                            </el-select> -->
                        </div>
                    </div>
                </div>
                <!-- 箱量 -->
                <div class="positionType" v-show="packingtypeValue == 'FCL'">
                    <div class="positionType">
                        <p>箱量</p>
                        <div class="positionType_Input">
                            <el-select v-model="containerCapacityVlaue" placeholder="请选择">
                                <el-option :value="containerCapacityVlaue"> </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="positionType" v-show="packingtypeValue == 'LCL'">
                    <div class="positionType">
                        <p>件数</p>
                        <div class="positionType_Input">
                            <el-select v-model="pNumValue" placeholder="请选择">
                                <el-option :value="pNumValue"> </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="positionType" v-show="packingtypeValue == 'LCL'">
                    <div class="positionType">
                        <p>毛重</p>
                        <div class="positionType_Input">
                            <el-select v-model="kkgsValue" placeholder="请选择">
                                <el-option :value="kkgsValue"> </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="positionType" v-show="packingtypeValue == 'LCL'">
                    <div class="positionType">
                        <p>体积</p>
                        <div class="positionType_Input">
                            <el-select v-model="ccmbValue" placeholder="请选择">
                                <el-option :value="ccmbValue"> </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
            <ul>
                <li v-for="(item, index) in itemgoodsList" :key="index">
                    <div class="itemgoods">
                        <div class="itemgoods_Title"><span>物品{{ index + 1 }}</span></div>
                        <div class="itemContent">
                            <!-- 物品名 -->
                            <div class="itemName">
                                <p>物品名<span>*</span></p>
                                <div class="itemName_Input"><input type="text" v-model="item.itemName"
                                        placeholder="请输入"></div>
                            </div>
                            <!-- 毛重 -->
                            <div class="grossWeight smallInput">
                                <p>毛重:kgs<span>*</span></p>
                                <div class="itemName_Input"><input type="text" v-model="item.kgs" placeholder="请输入"
                                        @input="inputNum1">
                                </div>
                            </div>
                            <!-- 提交 -->
                            <div class="submitS smallInput">
                                <p>体积:cbm<span>*</span></p>
                                <div class="itemName_Input"><input type="text" v-model="item.cbm" placeholder="请输入"
                                        @input="inputNum2">
                                </div>
                            </div>
                            <!-- 件数 -->
                            <div class="numberSs smallInput">
                                <p>件数:pkg<span>*</span></p>
                                <div class="itemName_Input"><input type="text" v-model="item.pkg" placeholder="请输入"
                                        @input="inputNum3">
                                </div>
                            </div>
                            <!-- H.S.CODE -->
                            <div class="hscode smallInput">
                                <p>H.S.CODE<span>*</span></p>
                                <div class="itemName_Input"><input type="text" v-model="item.hsCode" placeholder="请输入">
                                </div>
                            </div>
                            <div class="increase" @click="adds(item)">增加</div>
                            <div class="deleteS" @click="deleteS(index)">删除</div>
                        </div>
                    </div>
                </li>
            </ul>

        </div>
        <!-- 提交 -->
        <div class="contractButton">
            <div class="contract">
                <div class="img" @click="checkS">
                    <img :src="checkSF ? img1 : img2" alt="">
                </div>
                <p>我已阅读<span @click="flagHeYue = true">《货运合约条款》</span></p>
            </div>
            <!-- 按钮 -->
            <div class="selectButton">
                <div class="previousStep" @click="previousStep"><span>上一步</span></div>
                <div class="submitt" @click="submitt"><span>提交</span></div>
            </div>
        </div>
        <moduleName v-if="flagHeYue" :handleMe="handleMe" :handleCloseMe="handleCloseMe"
            :shipperName='shipperList.corporateName'></moduleName>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl'
import moduleName from './tiaokuan.vue';
export default {
    name: "Information_Component",
    components: { moduleName },
    data() {
        return {
            modifyAddressS: false,
            flagHeYue: false,
            radio: false,// 单选按钮
            radio1: false,
            addAddressS1: false,
            addAddressS11: false,
            corporateName: "", //公司名称
            uName: "", //姓名
            address1: "",//地址
            // detailedAddress: "",//详细地址
            mailboxs: "",//邮箱
            postalCode: "",//邮政编码
            telPhone: "",//联系方式

            quereny1: false, //
            quereny2: false, //
            quereny3: false, //
            quereny4: false,

            modify: {   //修改
                corporateName: "", //公司名称
                uName: "",  //姓名
                address1: "", //地址
                // detailedAddress: "",//详细地址
                mailboxs: "", //邮箱
                postalCode: "", //邮政编码
                telPhone: "",//联系方式
            },

            inputServe: "", //关键字搜索

            addressList: [],
            addressClose: false, //地址溥
            addressClose1: false, //地址溥
            status: false, //表头
            token: sessionStorage.getItem("token"),
            value: "",
            orderType: "", //订单类型
            token: sessionStorage.getItem("token"),  //Token
            shipperList: {  //发货方   
                corporateName: "", //公司名称
                shipperName: '', //发货人姓名
                contactNumber: '', //联系电话
                detailedAddress: '',//详细地址
                mailbox: '', //邮箱
            },
            shipToList: { //收货方
                corporateName: "", //公司名称
                shipperName: '', //收货人姓名
                contactNumber: '', //联系电话
                detailedAddress: '',//详细地址
                mailbox: '', //邮箱
            },
            otherContacts: { //其他联系人
                notifierInformation: "", //通知人信息
                packingContact: "", //装箱联系人
                doorToDoorContact: "", //到门联系人
            },

            shippingSpaceValue: "", //仓位类型
            boxTypeList: '',
            boxTypeValue: '', //箱型
            containerCapacityVlaue: "", //箱量
            tradeMode: "", //贸易方式
            departure: "",  //起运港
            destination: "", //目的港
            // 
            detailList: [],
            img1: require("../../assets/BookingSpace/checkTrue.png"), //选中
            img2: require("../../assets/BookingSpace/checkFalse.png"), //未选中
            options: [],
            checkSF: false,//校验
            isDoor: '',
            itemgoodsList: [
                {
                    itemName: '',//物品名
                    pkg: '',//件数
                    kgs: '',//毛重
                    cbm: '',//体积
                    hsCode: '',// H.S.CODE
                    itemNameEn: "", //商品英文名称
                }
            ],
            selectData: [],
            selectData1: [],
            rowId: '', //修改Id

            packingtypeValue: "", //装箱类型
            pNumValue: 0, //件数
            kkgsValue: 0, //重量
            ccmbValue: 0, //体积

            paging: {
                total: 0,
                size: 5,
            },
        }
    },
    created() {
        console.log(this.$route.query.id);
        axios.get(`${baseUrl}/inquiry/detail`, {
            params: { id: this.$route.query.id },
            headers: { 'authorization': this.token }
        }).then(res => {
            console.log(res.data.result)
            this.departure = res.data.result.departure; //起运港
            this.destination = res.data.result.destination;//目的港
            this.orderType = res.data.result.cate; //订单类型
            this.boxTypeValue = res.data.result.tradeMode;  //箱型
            this.containerCapacityVlaue = res.data.result.cupboardnum; //箱量
            this.tradeMode = res.data.result.tradeMode;//贸易方式
            this.shippingSpaceValue = res.data.result.shippingSpace;//仓位类型
            this.packingtypeValue = res.data.result.packingtype; //装箱类型
            this.pNumValue = res.data.result.pkg; //件数
            this.kkgsValue = res.data.result.kgs; //重量
            this.ccmbValue = res.data.result.cbm; //重量
            // this.isDoor = res.data.result.isDoor; //是否到门
            if (res.data.result.isDoor == "否") {
                this.isDoor = '0'
            } else if (res.data.result.isDoor == "是") {
                this.isDoor = '1';
            }
        }).catch(err => {
            console.error(err);
        })
    },
    methods: {

        // #region  //限制输入文字
        inputNum1(e) {  //限制输入数字
            this.itemgoodsList.forEach((v, i) => {
                this.itemgoodsList[i].kgs = this.itemgoodsList[i].kgs.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
                this.itemgoodsList[i].kgs = this.itemgoodsList[i].kgs.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
                this.itemgoodsList[i].kgs = this.itemgoodsList[i].kgs.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
                this.itemgoodsList[i].kgs = this.itemgoodsList[i].kgs.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
            })
            // console.log(this.itemgoodsList.kgs);

        },
        inputNum2(e) {
            this.itemgoodsList.forEach((v, i) => {
                this.itemgoodsList[i].cbm = this.itemgoodsList[i].cbm.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
                this.itemgoodsList[i].cbm = this.itemgoodsList[i].cbm.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
                this.itemgoodsList[i].cbm = this.itemgoodsList[i].cbm.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
                this.itemgoodsList[i].cbm = this.itemgoodsList[i].cbm.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
            })
        },
        inputNum3(e) {
            this.itemgoodsList.forEach((v, i) => {
                this.itemgoodsList[i].pkg = this.itemgoodsList[i].pkg.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
                this.itemgoodsList[i].pkg = this.itemgoodsList[i].pkg.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
                this.itemgoodsList[i].pkg = this.itemgoodsList[i].pkg.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
                this.itemgoodsList[i].pkg = this.itemgoodsList[i].pkg.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
            })
        },
        //#endregion



        changeServe(e) {  //关键词搜索
            console.log("-", e.target.value);
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/member/addressList`, {
                //参数列表
                params: { keyWords: e.target.value },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    this.addressList = res.data.result.records;
                })
                .catch(err => {
                    console.error(err);
                })

        },

        handleMe() {
            this.flagHeYue = false;
            this.checkSF = true
        },
        handleCloseMe() {
            this.flagHeYue = false;
        },


        adds() { //增加
            this.itemgoodsList.push({
                itemName: '',//物品名
                kgs: '',//毛重
                cbm: '',//体积
                pkg: '',//件数
                hsCode: '',// H.S.CODE
                itemNameEn: "", //商品英文名称
            })
        },
        deleteS(index) { //删除
            if (this.itemgoodsList.length >= 2) {
                this.itemgoodsList.splice(index, 1)
            }
        },
        checkS() { //校验
            this.checkSF = !this.checkSF;
        },
        radioData(scope, row) {   //选中的
            this.selectData = scope;
            console.log(this.selectData);
        },
        radioData1(scope, row) {   //选中的
            this.selectData1 = scope;
            console.log(this.selectData1);
        },

        addAdd() {
            this.addressClose = false;
            this.addAddressS1 = true;
            // this.addAddressS1 = true;
        },
        addAdd1() {  //收货新增
            this.addressClose1 = false
            this.addAddressS11 = true;

        },
        handleCloseAdd() {  //新增地址溥关闭
            console.log("$");
            this.addAddressS1 = false;
            this.addressClose = true;
        },
        handleCloseAdd1() {  //
            console.log("^^");
            this.addAddressS11 = false;
            this.addressClose1 = true;

        },
        handleSizeChange(val) { //分页
            var token = sessionStorage.getItem("token");
            axios.get(`${baseUrl}/member/addressList`, {
                params: { pageNo: val },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    // console.log()
                    if (res.data.success == true) {
                        this.addressList = res.data.result.records;
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleConfir() {  //确认
            this.shipperList.corporateName = this.selectData.company; //姓名
            this.shipperList.shipperName = this.selectData.contact; //姓名
            this.shipperList.contactNumber = this.selectData.tel; //联系电话
            this.shipperList.detailedAddress = this.selectData.addr; //详细地址
            this.shipperList.mailbox = this.selectData.email;
            this.addressClose = false;
            this.quereny1 = false;
        },
        handleConfir1() {  //确认
            console.log("%%%");
            this.shipToList.corporateName = this.selectData1.company; //姓名
            this.shipToList.shipperName = this.selectData1.contact; //姓名
            this.shipToList.contactNumber = this.selectData1.tel; //联系电话
            this.shipToList.detailedAddress = this.selectData1.addr; //详细地址
            this.shipToList.mailbox = this.selectData1.email;
            this.addressClose1 = false;

            /* 
            shipToList: { //收货方
                shipperName: '', //收货人姓名
                contactNumber: '', //联系电话
                detailedAddress: '',//详细地址
                mailbox: '', //邮箱
            },
            */

        },
        confirms() {  //点击确认 添加

            var token = sessionStorage.getItem("token")
            if (this.corporateName == "") {
                this.$message({
                    showClose: true,
                    message: '请输入公司名称',
                    type: 'warning'
                });
                return false
            }
            if (this.uName == "") {
                this.$message({
                    showClose: true,
                    message: '请输入姓名',
                    type: 'warning'
                });
                return false
            } else if (this.telPhone == "") {
                this.$message({
                    showClose: true,
                    message: '请输入联系方式',
                    type: 'warning'
                });
                return false
            } else if (this.address1 == "") {
                this.$message({
                    showClose: true,
                    message: '请输入详细地址',
                    type: 'warning'
                });
                return false
            } else if (this.mailboxs == "") {
                this.$message({
                    showClose: true,
                    message: '请输入邮箱',
                    type: 'warning'
                });
                return false
            }
            else {
                axios.post(`${baseUrl}/member/addressAdd`, {
                    company: this.corporateName,
                    contact: this.uName,
                    tel: this.telPhone,
                    email: this.mailboxs,
                    addr: this.address1,
                    postCode: this.postalCode,
                }, {
                    headers: {
                        'authorization': token
                    }
                })
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message({
                                showClose: true,
                                message: '添加成功',
                                type: 'success'
                            });
                        }
                        this.corporateName = this.uName = this.telPhone = this.address1 = this.mailboxs = this.postalCode = ""
                        this.addAddressS1 = false;
                        console.log(res)
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }

        },
        handelXinXi() {  //发货方地址
            this.quereny1 = true;
            console.log(this.selectData);
            console.log("^^");
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/member/addressList`, {
                //参数列表
                // params: { id: "1585631721086554114" },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result.total);
                    this.paging.total = res.data.result.total;
                    this.addressClose = true; //发货地址溥
                    this.addressList = res.data.result.records;
                })
        },
        handelXinXi1() {  //收货方地址
            console.log(this.selectData1);
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/member/addressList`, {
                //参数列表
                // params: { id: "1585631721086554114" },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result)
                    this.addressClose1 = true
                    this.addressList = res.data.result.records;
                    this.paging.total = res.data.result.total;


                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleClose() {  //关闭地址溥
            this.addressClose = false;
            this.addressClose1 = false;
        },

        handleXiuGai(row) {  //修改
            console.log(row);
            this.modifyAddressS = true;
            // this.addressClose = false;
            this.rowId = row.id;
            this.modify.corporateName = row.company;//公司名称
            this.modify.uName = row.contact;    //姓名
            this.modify.address1 = row.addr;   //地址
            // this.modify.detailedAddress = addr2;       //详细地址
            this.modify.mailboxs = row.email;//邮箱
            this.modify.postalCode = row.postCode;//邮政编码
            this.modify.telPhone = row.tel;//联系方式
        },
        handleXiuGai1(row) {
            // this.addressClose1 = false;
            this.modifyAddressS = true;
            console.log("$", row);
            this.rowId = row.id;
            this.modify.corporateName = row.company
            this.modify.uName = row.contact;    //姓名
            this.modify.address1 = row.addr;   //地址
            // this.modify.detailedAddress = addr2;       //详细地址
            this.modify.mailboxs = row.email;      //邮箱
            this.modify.postalCode = row.postCode;//邮政编码
            this.modify.telPhone = row.tel;      //联系方式

        },
        modifyConfirms() {  //确认修改按钮
            console.log("%%");
            var token = sessionStorage.getItem("token");
            if (this.modify.corporateName == "") {
                this.$message({
                    showClose: true,
                    message: '请输入公司名称',
                    type: 'warning'
                });
                return false
            } else if (this.modify.uName == "") {
                this.$message({
                    showClose: true,
                    message: '请输入姓名',
                    type: 'warning'
                });
                return false
            } else if (this.modify.telPhone == "") {
                this.$message({
                    showClose: true,
                    message: '请输入联系方式',
                    type: 'warning'
                });
                return false
            } else if (this.modify.address1 == "") {
                this.$message({
                    showClose: true,
                    message: '请输入详细地址',
                    type: 'warning'
                });
                return false
            } else if (this.modify.mailboxs == "") {
                this.$message({
                    showClose: true,
                    message: '请输入邮箱',
                    type: 'warning'
                });
                return false
            } else {
                axios.post(`${baseUrl}/member/addressEdit`, {
                    company: this.modify.corporateName,
                    contact: this.modify.uName,
                    tel: this.modify.telPhone,
                    email: this.modify.mailboxs,
                    addr: this.modify.address1,
                    postCode: this.modify.postalCode,
                    id: this.rowId,
                }, {
                    headers: {
                        'authorization': token
                    }
                }).then(res => {
                    if (res.data.success == true) {
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                        });
                        console.log(res);
                        var token = sessionStorage.getItem("token")
                        axios.get(`${baseUrl}/member/addressList`, {
                            //参数列表
                            // params: { id: "1585631721086554114" },
                            //请求头配置  
                            headers: { 'authorization': token }
                        }).then(res => {
                            this.addressList = res.data.result.records;
                        })
                        this.modifyAddressS = false;
                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'error'
                        });
                    }


                })
                    .catch(err => {
                        console.error(err);
                    });

                this.addressClose = true;
            }
        },
        handlenotice() {  //通知人信息
            this.quereny2 = true;
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/member/addressList`, {
                //参数列表
                // params: { id: "1585631721086554114" },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result)
                    this.addressClose = true
                    this.addressList = res.data.result.records;
                })
                .catch(err => {
                    console.error(err);
                })

            /* 
             otherContacts: { //其他联系人
                            notifierInformation: "", //通知人信息
                            packingContact: "", //装箱联系人
                            doorToDoorContact: "", //到门联系人
                        },
            */
        },
        handleConfir2() { //
            this.otherContacts.notifierInformation = this.selectData.company + ',' + this.selectData.contact + ',' + this.selectData.tel + ',' + this.selectData.addr + ',' + this.selectData.email;
            this.addressClose = false;
            this.quereny2 = false;
        },
        handleConfir3() { //
            this.otherContacts.packingContact = this.selectData.company + ',' + this.selectData.contact + ',' + this.selectData.tel + ',' + this.selectData.addr + ',' + this.selectData.email;
            this.addressClose = false;
            this.quereny3 = false;
        },
        handlePacking() {  //装箱联系人
            this.quereny3 = true;
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/member/addressList`, {
                //参数列表
                // params: { id: "1585631721086554114" },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result)
                    this.addressClose = true
                    this.addressList = res.data.result.records
                        ;


                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleDoor() {  //到门联系人
            this.quereny4 = true;
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/member/addressList`, {
                //参数列表
                // params: { id: "1585631721086554114" },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result)
                    this.addressClose = true
                    this.addressList = res.data.result.records;


                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleConfir4() {
            this.otherContacts.doorToDoorContact = this.selectData.company + ',' + this.selectData.contact + ',' + this.selectData.tel + ',' + this.selectData.addr + ',' + this.selectData.email;
            this.addressClose = false;
            this.quereny4 = false;
        },

        handleShanChu(row) { //删除
            var token = sessionStorage.getItem("token")
            console.log(row.id);
            axios.delete(`${baseUrl}/member/addressDelete`, {
                params: {
                    id: row.id,
                },
                headers: {
                    'authorization': token
                }
            })
                .then(res => {
                    console.log(res)
                    if (res.data.success == true) {
                        this.$message({
                            showClose: true,
                            message: '删除成功',
                            type: 'success'
                        });
                        var token = sessionStorage.getItem("token")
                        axios.get(`${baseUrl}/member/addressList`, {
                            //参数列表
                            // params: { id: "1585631721086554114" },
                            //请求头配置  
                            headers: { 'authorization': token }
                        }).then(res => {
                            this.addressList = res.data.result.records;
                        })
                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'error'
                        });
                    }

                })
                .catch(err => {
                    console.error(err);
                })
        },


        previousStep() { //上一步
            this.$router.go(-1)
        },
        submitt() { //提交
            
            var exportCargoOrderGoodsList = this.itemgoodsList;
            console.log(exportCargoOrderGoodsList);
            if (this.shipperList.corporateName == '' || this.shipperList.shipperName == "" || this.shipperList.contactNumber == "" || this.shipperList.detailedAddress == "") {
                this.$message({
                    showClose: true,
                    message: '请输入发货方信息',
                    type: 'warning'
                });
                return false
            } else if (this.shipToList.corporateName == '' || this.shipToList.shipperName == "" || this.shipToList.contactNumber == "" || this.shipToList.detailedAddress == "") {
                this.$message({
                    showClose: true,
                    message: '请输入收货方信息',
                    type: 'warning'
                });
                return false
            } else if (this.otherContacts.notifierInformation == '' || this.otherContacts.packingContact == "") {
                this.$message({
                    showClose: true,
                    message: '请输入其他联系人信息',
                    type: 'warning'
                });
                return false
            }
            else if (this.itemgoodsList[0].itemName == "" || this.itemgoodsList[0].pkg == "" || this.itemgoodsList[0].kgs == "" || this.itemgoodsList[0].cbm == "" || this.itemgoodsList[0].cbm == "hsCode") {
                this.$message({
                    showClose: true,
                    message: '请最少填写一个物品名',
                    type: 'warning'
                });
                return false
            } else if (!this.checkSF) {
                this.$message({
                    showClose: true,
                    message: '请勾选合约条款',
                    type: 'warning'
                });
                return false
            } else {

                axios.post(`${baseUrl}/inquiryOrder/add`, {
                    orderType: this.orderType, //订单类型 
                    shippingSpace: this.shippingSpaceValue,  //仓为类型soc或coc
                    planNo: this.$route.query.id,    //询价单id
                    tradeMode: this.tradeMode, //贸易方式
                    departure: '',    //如果海运传启运港与目的港，发货城市与收货城市为""
                    destination: '',
                    froms: this.departure,  //如果铁运传发货城市与收货城市,启运港与目的港为""
                    tos: this.destination,
                    shipperComp: this.shipperList.corporateName, //发货公司
                    shipper: this.shipperList.shipperName, //发货人  
                    shipperMobile: this.shipperList.contactNumber, //发货人电话
                    shipperEmail: this.shipperList.mailbox || '', //发货人邮箱
                    shipperAddr: this.shipperList.detailedAddress, //发货人地址
                    consigneeComp: this.shipToList.corporateName, //收货公司
                    consignee: this.shipToList.shipperName, //收货人
                    consigneeMobile: this.shipToList.contactNumber, //收货人电话
                    consigneeEmail: this.shipToList.shipperName, //收货人邮箱
                    consigneeAddr: this.shipToList.detailedAddress, //收货人地址
                    postalCode: this.shipToList.mailbox || "", //收货人邮编
                    notifyInfo: this.otherContacts.notifierInformation,  //通知人
                    // notifyPartyMobile: this.shipToList.contactNumber,  //通知人电话
                    // notifyPartyEmail: this.shipToList.mailbox || "",  //通知人邮箱
                    // notifyPartyAddr: this.shipToList.detailedAddress,  //通知人地址
                    isDoor: this.isDoor,   //是否到门，是为1,非为0 字符串类型
                    packingInfo: this.otherContacts.packingContact || "",   //到门联系人  没有为""
                    //this.otherContacts.notifierInformation //通知人信息
                    finalInfo: this.otherContacts.doorToDoorContact || "", //到门电话   没有为""
                    // finalEmail: "", //到门邮箱   没有为""
                    // finalAddr: "",  //到门地址  没有为""
                    cupboard: this.boxTypeValue, //柜子柜型"
                    cupboardNum: this.containerCapacityVlaue,  //柜子数量
                    exportCargoOrderGoodsList
                    
                }, {
                    headers: {
                        'authorization': this.token
                    }
                })
                    .then(res => {
                        console.log(res)
                        if (res.data.code == 200 && res.data.success == true) {
                            this.$message({
                                showClose: true,
                                message: '下单成功',
                                type: 'success'
                            });
                            this.$router.replace("/EnquiryCabinet/OceanShipping")
                        } else if (res.data.success == false) {
                            let message = res.data.message
                            this.$message({
                                showClose: true,
                                message: message,
                                type: 'error'
                            });
                            return false
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.xiugaiMask {
    z-index: 200 !important;
}

.addressPu {
    position: absolute;
    // position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(97, 97, 97, 0.3);
    z-index: 10;



    .addressBox {
        position: absolute;
        left: 50%;
        top: 172px;
        transform: translate(-50%);
        width: 900px;
        height: 566px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
        padding-bottom: 32px;

        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            color: #FFFFFF;
            cursor: pointer;
        }

        .addressTitle {
            width: 900px;
            height: 64px;
            background: #1890FF;
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: 64px;
        }

        .addressInput {
            padding: 32px 32px 0;
            display: flex;
            justify-content: space-between;
            height: 260px;

            .addressInputLeft,
            .addressInputRight {
                width: 320px;

                .inputbox {
                    margin-top: 20px;

                    p {
                        font-size: 14px;
                        font-weight: 700;
                        color: #333333;
                    }

                    .inputs {
                        width: 320px;
                        height: 25px;
                        // border-bottom: 1px solid;

                        margin-top: 17px;

                        input {
                            width: 100%;
                            height: 100%;
                            outline: none;
                            border: 0;
                            border-bottom: 1px solid #999999;
                            padding-bottom: 8px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #999999;
                        }

                        input:focus {
                            border-bottom: 1px solid #1890FF;
                        }
                    }
                }

                .inputbox:nth-child(1) {
                    margin-top: 0;
                }
            }

            .addressInputRight {
                width: 320px;
            }
        }

        .intelligence {
            padding: 0 32px;
            margin-top: 30px;

            .intelligenceTitle {
                font-size: 14px;
                font-weight: 600;
                color: #333333;
            }

            .intelligenceInput {
                position: relative;
                display: flex;
                width: 736px;
                height: 90px;
                background: #F9F9F9;
                margin-top: 8px;

                // input {
                //     border: 0;
                // }
                .textarea {
                    width: 580px;
                }

                #textarea {
                    padding: 16px 0 0 16px;
                    width: 100%;
                    height: 90px;
                    border: none;
                    outline: none;
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    resize: none;
                    background-color: #F9F9F9;
                }

                .intelligenceBtn {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    display: flex;

                    .btn1 {
                        width: 70px;
                        height: 30px;
                        background: #999999;
                        border-radius: 2px 2px 2px 2px;
                        text-align: center;
                        line-height: 30px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                        cursor: pointer;
                    }

                    .btn1:nth-child(2) {
                        margin-left: 14px;
                        background: #D4252C;
                    }
                }


            }

        }

        .addressPuBtn {
            width: 100px;
            height: 34px;
            background: #D4252C;
            border-radius: 30px 30px 30px 30px;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: 34px;
            margin: 41px auto 0;
        }
    }
}

.information {
    // position: relative;
    width: 100%;

    // height: 100%;
    .addressXin {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        box-shadow: 0px 3px 6px 1px rgba(51, 51, 51, 0.1);
        z-index: 100;

        .addressBOX {
            position: relative;
            width: 1000px;
            height: 582px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
            margin: 100px auto 0;

            .addressBOXTitle {
                position: relative;
                width: 100%;
                height: 64px;
                background: #1890FF;
                text-align: center;
                line-height: 64px;
                font-size: 20px;
                font-weight: 400;
                color: #FFFFFF;

                span:nth-child(2) {
                    position: absolute;
                    right: 35px;
                    top: 0;
                    cursor: pointer;
                }
            }

            .serverAdd {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 64px;
                padding: 0 32px;

                .serverInput {
                    position: relative;
                    width: 208px;
                    height: 34px;
                    background: #F2F2F2;
                    border-radius: 30px 30px 30px 30px;
                    overflow: hidden;
                    margin-top: 15px;

                    i {
                        position: absolute;
                        display: inline;
                        left: 10px;
                        top: 50%;
                        color: #999999;
                        transform: translateY(-50%);
                    }

                    input {
                        width: 100%;
                        height: 100%;
                        border-radius: 30px 30px 30px 30px;
                        background: #F2F2F2;
                        line-height: 43px;
                        font-size: 14px;
                        font-weight: 400;
                        padding-left: 25px;
                        outline: none;
                        border: 0;
                        color: #999999;
                    }
                }

                .serverAddBtn {
                    font-size: 16px;
                    font-weight: 400;
                    color: #D4252C;
                    cursor: pointer;
                }
            }
        }
    }

    .tabs {
        margin-top: 24px;
        height: 240px;

        ::v-deep .nana {
            text-align: center;
        }

        ::v-deep .el-table_1_column_2 {
            text-align: center;
        }

        ::v-deep .el-table__fixed::before {
            background-color: transparent;
        }

        ::v-deep .el-table__fixed-right::before {
            background-color: transparent;
        }

        .el-button {
            padding: 6px 12px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
        }

        .bianji {
            display: flex;
            justify-content: right;
        }

        .img1 {
            width: 18px;
            height: 18px;
            margin-right: 24px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .img2 {
            width: 18px;
            height: 18px;
            margin-right: 32px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .el-button:nth-child(2) {
            background: #D4252C;
            box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
        }

        .scopes {
            display: flex;
            align-items: center;

            .radio {
                margin-right: 18px;
            }
        }
    }

    .paging {
        display: flex;
        justify-content: center;
        position: relative;
        // float: right;
        margin-top: 32px;
        // background-color: #FFFFFF;
        z-index: 100;
    }

    .confirmBtn {
        position: absolute;
        right: 32px;
        bottom: 24px;
        width: 100px;
        height: 34px;
        background: #1890FF;
        border-radius: 30px 30px 30px 30px;
        text-align: center;
        line-height: 34px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
    }

    .information_Box {
        width: 1200px;
        height: 443px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        margin: 24px auto;

        .information_Title {
            position: relative;
            height: 58px;
            padding: 16px 25px 0 24px;

            span {
                font-size: 18px;
                font-weight: 400;
                color: #333333;
            }
        }

        .information_Title::before {
            position: absolute;
            bottom: 0;
            content: '';
            width: 1151px;
            height: 1px;
            background-color: #999999;
        }

        // 详细信息
        .detailedInformation {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 0 24px;
            margin-top: 24px;

            .goods {
                width: 510px;

                input {
                    width: 100%;
                    height: 100%;
                    border: 0;
                    outline: none;
                    border-radius: 2px 2px 2px 2px;
                    padding-left: 8px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #999999;

                }

                input:focus {
                    box-shadow: 0px 0px 6px 1px rgba(24, 144, 255, 0.6);
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;

                    span {
                        margin-left: 4px;
                        color: #D4252C;
                    }
                }

                // 姓名
                .deliverGoods {

                    .deliverGoods_Input {
                        display: flex;
                        width: 300px;
                        height: 40px;
                        background: #FFFFFF;
                        border-radius: 2px 2px 2px 2px;
                        margin-top: 8px;

                        input {
                            width: 260px;
                            height: 100%;
                            font-size: 16px;
                            border: 0;
                            padding-left: 8px;
                            border: 1px solid #999999;
                            border-radius: 2px 2px 2px 2px;
                            outline: none;
                        }

                        input:focus {
                            box-shadow: 0px 0px 6px 1px rgba(24, 144, 255, 0.6);
                        }

                        .img {
                            width: 40px;
                            height: 40px;
                            background: linear-gradient(180deg, #68B7FF 0%, #1890FF 100%);
                            border-radius: 2px 2px 2px 2px;
                            overflow: hidden;

                            img {
                                display: block;
                                width: 26px;
                                height: 26px;
                                margin: auto;
                                transform: translateY(25%);
                            }
                        }
                    }
                }

                // 电话
                .telephoneNum {
                    margin-top: 24px;

                    .telephoneNum_Input {
                        display: flex;
                        align-items: center;
                        width: 380px;
                        height: 40px;
                        background: #FFFFFF;
                        border-radius: 2px 2px 2px 2px;
                        border: 1px solid #999999;
                        margin-top: 8px;

                        input {
                            // width: 100%;
                            flex: 1;
                            height: 100%;
                        }

                        input:focus {
                            box-shadow: 0px 0px 6px 1px rgba(24, 144, 255, 0.6);
                        }
                    }
                }

                // 详细地址
                .detailedAddress {
                    margin-top: 24px;



                    .detailedAddress_Input {
                        margin-top: 8px;
                        width: 510px;
                        height: 40px;
                        background: #FFFFFF;
                        border-radius: 2px 2px 2px 2px;
                        border: 1px solid #999999;
                    }
                }

                .mailbox {
                    margin-top: 24px;

                    .mailbox_Input {
                        width: 300px;
                        height: 40px;
                        background: #FFFFFF;
                        border-radius: 2px 2px 2px 2px;
                        border: 1px solid #999999;
                        margin-top: 8px;
                    }
                }
            }
        }
    }

    // 其他联系人信息
    .otherContacts {
        width: 1200px;
        height: 184px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        margin: 24px auto;
        padding: 16px 24px 0;

        .otherContacts_Title {
            position: relative;
            height: 58px;
            font-size: 18px;
            color: #333333;
            font-weight: 400;
        }

        .otherContacts_Title::before {
            position: absolute;
            bottom: 0;
            content: '';
            width: 1151px;
            height: 1px;
            background-color: #999999;
        }

        .contactInformation {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 24px;

            .notice {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }

                .notice_Input {
                    display: flex;
                    width: 300px;
                    height: 40px;
                    background: #FFFFFF;


                    border-radius: 2px 2px 2px 2px;
                    opacity: 1;
                    // overflow: hidden;
                    margin-top: 8px;

                    input {
                        width: 260px;
                        height: 100%;
                        outline: none;
                        border-radius: 2px 2px 2px 2px;
                        padding-left: 8px;
                        border: 1px solid #999999;
                        border-right: transparent;
                    }

                    input:focus {
                        box-shadow: 0px 0px 6px 1px rgba(24, 144, 255, 0.6);
                        border-color: #999999;
                    }

                    .img {
                        width: 40px;
                        height: 40px;
                        background: linear-gradient(180deg, #68B7FF 0%, #1890FF 100%);
                        // border-radius: 2px 2px 2px 2px;
                        cursor: pointer;

                        img {
                            display: block;
                            margin: auto;
                            transform: translateY(25%);
                            cursor: pointer;
                        }
                    }
                }

                .noticeS {
                    // box-shadow: 0px 0px 6px 1px rgba(24, 144, 255, 0.6);
                }
            }
        }
    }

    .itemInformation {
        width: 1200px;
        // min-width: 500px;
        // min-height: 500px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        margin: 0 auto;
        padding: 16px 24px 40px;
        // overflow: hidden;

        .itemInformation_Title {
            position: relative;
            height: 58px;
            font-size: 18px;
            font-weight: 400;
            color: #333333;
        }

        .itemInformation_Title::before {
            position: absolute;
            bottom: 0;
            content: '';
            width: 1151px;
            height: 1px;
            background-color: #999999;
        }

        .positionS {
            display: flex;
            margin-top: 16px;

            p {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }

            .positionType {
                margin-left: 24px;

                .positionType_Input {
                    width: 167px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    margin-top: 8px;
                }
            }

            .positionType:nth-child(1) {
                margin-left: 0;
            }
        }

        .itemgoods {
            margin-top: 30px;

            .itemgoods_Title {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }

            .itemContent {
                display: flex;
                justify-content: space-between;
                width: 1152px;
                height: 96px;
                background: #FFFFFF;
                box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
                margin-top: 8px;
                border-radius: 4px;
                padding: 16px 15px 18px 16px;

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;

                    span {
                        margin-left: 4px;
                        color: #D4252C;
                    }
                }

                .itemName {
                    .itemName_Input {
                        width: 260px;
                        height: 40px;
                        background: #FFFFFF;
                        border-radius: 4px 4px 4px 4px;
                        border: 1px solid #999999;
                        margin-top: 8px;

                        input {
                            border: 0;
                            outline: none;
                            width: 100%;
                            height: 100%;
                            // border-radius: 4px 4px 4px 4px;
                            padding-left: 16px;
                        }

                        input:focus {
                            box-shadow: 0px 0px 6px 1px rgba(24, 144, 255, 0.6);
                        }
                    }
                }

                .smallInput {
                    .itemName_Input {
                        width: 160px;
                        height: 40px;
                        background: #FFFFFF;
                        border-radius: 4px 4px 4px 4px;
                        border: 1px solid #999999;
                        margin-top: 8px;

                        input {
                            border: 0;
                            outline: none;
                            width: 100%;
                            height: 100%;
                            border-radius: 4px 4px 4px 4px;
                            padding-left: 16px;
                        }

                        input:focus {
                            box-shadow: 0px 0px 6px 1px rgba(24, 144, 255, 0.6);
                        }
                    }
                }

                .increase,
                .deleteS {
                    width: 48px;
                    height: 24px;
                    background: #1890FF;
                    box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
                    border-radius: 4px 4px 4px 4px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 35px;
                    cursor: pointer;
                }

                .deleteS {
                    background: #D4252C;
                }
            }
        }
    }

    .contractButton {
        // position: absolute;
        // bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        height: 64px;
        background: #FFFFFF;
        margin: 16px auto;
        padding: 0 64px;

        .contract {
            display: flex;

            .img {
                width: 18px;
                height: 18px;
                background: rgba(255, 255, 255, 0.01);
                margin: auto 0;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            p {
                font-size: 18px;
                font-size: 18px;
                font-weight: 400;

                span {
                    cursor: pointer;
                    color: #D4252C;
                }
            }
        }

        .selectButton {
            display: flex;

            div {
                width: 150px;
                height: 40px;
                border-radius: 30px 30px 30px 30px;
                text-align: center;
                line-height: 40px;
                font-size: 18px;
                font-weight: 400;
            }

            .previousStep {
                border: 1px solid #1890FF;
                color: #1890FF;
                margin-right: 32px;
                cursor: pointer;
            }

            .submitt {

                background: #1890FF;
                cursor: pointer;
                color: #FFFFFF;
            }
        }
    }
}
</style>